import { Component, ElementRef, ViewChild } from '@angular/core';
import { GlobalBaseComponent } from 'src/app/app/components/global-base/global-base.component';
import { SmartChatService } from '../../services/smart-chat/smart-chat.service';
import { AbstractControl, FormGroup, Validators } from '@angular/forms';
import { loadingState } from '../../operators/loading-state.operator';
import { BehaviorSubject, Subject, of, switchMap, takeUntil } from 'rxjs';
import { v4 as uuidv4 } from 'uuid';
import Typed from 'typed.js';
import { untilDestroyed } from '@ngneat/until-destroy';
import { DateTime } from 'luxon';

@Component({
	selector: 'app-smart-chat',
	templateUrl: './smart-chat.component.html',
	styleUrls: ['./smart-chat.component.scss']
})
export class SmartChatComponent extends GlobalBaseComponent {
	//////// AUN FALTA POR CULMINAR:
	//////// 1. Paginado de mensajes hacia arriba
	//////// 2. Paginado de conversaciones hacia abajo
	//////// 3. KeyBindings de salto de linea y enviar mensaje
	//////// 4. Error de mensajes de asistente que quedan en blanco
	//////// 5. Empty State conversacion nueva
	//////// 6. Empty State no hay conversacion seleccionada
	//////// 7. Maquetado

	@ViewChild('messagesWrapper') private messagesWrapper!: ElementRef;

	protected unSubscribeThread: Subject<void> = new Subject<void>();

	// loadingState.creatingThread  -> Se esta creando una nueva conversacion
	// loadingState.loadingThread   -> Se esta cargando una conversacion
	// loadingState.updatingThread  -> Se esta cargando una conversacion (update)
	// loadingState.sendingMessage  -> Se esta enviando un mensaje
	// loadingState.pendingRun      -> Se esta esperando actualizacion de un run

	hostUrl: string = ''; // Ruta host para renderizar el componente al 100% de la pantalla
	threads: any[] = []; // Conversaciones disponibles
	thread: any = null; // Conversacion actual

	componentStates: any = {
		// Estados del componente
		threadsMenu: true, // Visible el listado de conversaciones
		maximize: false, // Esta maximixada la ventana del chat
		close: false // Esta cerrada la ventana del chat
	};

	chatForm: FormGroup;

	constructor(private smartChatService: SmartChatService) {
		super();

		// Definir grupos de estados de carga
		this.loadingStates = {
			creatingThread: new BehaviorSubject<boolean>(false),
			loadingThread: new BehaviorSubject<boolean>(false),
			updatingThread: new BehaviorSubject<boolean>(false),
			sendingMessage: new BehaviorSubject<boolean>(false),
			pendingRun: new BehaviorSubject<boolean>(false)
		};

		// Iniciar Formulario
		this.chatForm = this.formBuilder.group({
			message: [
				'',
				[
					Validators.required,
					(control: AbstractControl) => {
						// Validadar que el campo no sean espacios en blanco o saltos de linea
						const value = control.value ? control.value.trim() : control.value;
						return value == '' ? { empty: true } : null;
					}

				]

			]
		});

		this.loadingStatesSubscriptions();
		this.populateLocalThreads();
	}

	// extends AfterViewInit
	override ngAfterViewInitOverride() {
		// Deshabilitado por defecto
		this.chatForm.disable();
	}

	// Gestionar eventos de subscripcion a los estados de carga
	// PROCESO VALIDADO
	loadingStatesSubscriptions() {
		// Si alguno de los estados esta activo bloquear el formulario
		const shoudLockChatForm = () => {
			if (this.loadingStates.sendingMessage.value || this.loadingStates.pendingRun.value || this.loadingStates.loadingThread.value || this.loadingStates.creatingThread.value) {
				this.chatForm.disable();
			} else {
				this.chatForm.enable();
			}
		};

		// Se esta enviando un mensaje
		this.loadingStates.sendingMessage.subscribe({
			next: (value: boolean) => {
				shoudLockChatForm();
			}
		});

		// Se esta esperando actualizacion de un run
		this.loadingStates.pendingRun.subscribe({
			next: (value: boolean) => {
				shoudLockChatForm();
			}
		});

		// Se esta cargando una conversacion
		this.loadingStates.loadingThread.subscribe({
			next: (value: boolean) => {
				shoudLockChatForm();
			}
		});

		// Se esta creando una nueva conversacion
		this.loadingStates.creatingThread.subscribe({
			next: (value: boolean) => {
				shoudLockChatForm();
			}
		});
	}

	// Crear conversacion nueva
	// PROCESO VALIDADO
	// SE NECESITA MEJORAR EL MECANISMO QUE RENDERIZA LA CONVERSACION PARA NO DEPENDER DE VARIABLES DE MEMORIA LOCAL (pushNewThread)
	createThread() {
		this.smartChatService
			.create()
			.pipe(takeUntil(this.ngUnsubscribe), loadingState(this.loadingStates.creatingThread))
			.subscribe({
				next: (data) => {
					// Insertar conversacion nueva y reproducir animacion
					this.pushNewThread(data.data);

					// Guardar conversaciones en el localStorage (DEMO)
					this.saveLocalThreads();

					// Establecer la nueva conversacion como activa
					this.setActiveThread(data.data.thread);
				},
				error: (error) => {
					console.log(error);
				}
			});
	}

	// Reproducir animacion de la nueva conversacion
	async pushNewThread(data: any) {
		// Esta promesa se completa al finalizar la animacion de escritura
		const typedAnimation = (typedId: string, content: string, threadId: string) =>
			new Promise<void>((r) => {
				setTimeout(() => {
					// https://github.com/mattboldt/typed.js/
					const typed = new Typed(`#${typedId}`, {
						strings: [content],
						showCursor: false,
						typeSpeed: 30,
						autoInsertCss: false,
						onComplete: (self) => {
							// Remover typedId para preservar estructura original del elemento
							// y prevenir error del maquetado al ejecutar el metodo destroy();
							if (this.threads) {
								if (this.threads[0]) {
									const thread = this.threads.find((thread: any) => thread.threadId == threadId);
									delete thread.typedId;
								}
							}

							self.destroy();
							r();
						}
					});
				}, 300); // <-- Este es el tiempo adecuando segunn la animacion "-intro-x" del elemento en el maquetado
			});

		const typedId = `typed-${uuidv4()}`;

		this.threads.unshift({
			name: 'MALTRATO FÍSICO Y PSICOLÓGICO DERIVADOS DE GOLPES A MENOR DE EDAD',
			assistantId: data.assistant,
			threadId: data.thread,
			typedId: typedId
		});

		typedAnimation(typedId, 'MALTRATO FÍSICO Y PSICOLÓGICO DERIVADOS DE GOLPES A MENOR DE EDAD', data.thread);
	}

	// Obtener - Actualizar mensajes de conversacion
	// PROCESO VALIDADO
	// FALTA HACER QUE PUEDA PAGINAR LOS MENSAJES
	retrieveThread(newFetch: boolean = false) {
		const threadId = this.thread.threadId;

		// Es un loading o un update
		const behaviorSubject = newFetch ? this.loadingStates.loadingThread : this.loadingStates.updatingThread;

		this.smartChatService
			.getThread(threadId)
			.pipe(takeUntil(this.unSubscribeThread), loadingState(behaviorSubject))
			.subscribe({
				next: (data) => {
					this.thread.messages = data.data.messages;

					if (newFetch) {
						this.scrollToNew();
					}

					// Al obtener runs pendientes llegan mensajes sin contenido
					// (ya que se estan generando en estos run)
					// Por lo cual se debe procesar los runs para ver sus estados y actualizar dichos mensajes pendientes
					if (data.data.pendingRuns) {
						this.populatePendingRuns(data.data.pendingRuns);
					}
				},
				error: (error) => {
					console.log(error);
				}
			});
	}

	// Habilitar run pendiente bajo demanda
	// PROCESO VALIDADO
	populatePendingRuns(runsId: any[]) {
		// Eliminar mensajes vacios producto de runs pendientes
		runsId.forEach((run) => {
			this.thread.messages = this.thread.messages.filter((message: any) => message.run_id !== run.id);
		});

		if (runsId[0]) {
			// Esperar respuesta del run
			this.getRunStatus(runsId[0].id, this.thread.threadId, this.thread.assistantId);
		}
	}

	// Obtener actualizacion del mensaje pendiente
	// PROCESO VALIDADO
	getRunStatus(runId: string, threadId: string, assistantId: string) {
		this.smartChatService
			.getRunStatus(runId, threadId, assistantId)
			.pipe(takeUntil(this.unSubscribeThread), loadingState(this.loadingStates.pendingRun))
			.subscribe({
				next: (data) => {
					this.pushAssistantMessages(data.data.messages);

					// Si al comprobar el run actual se detecta que aun existen
					// runs pendientes se itera una vez mas sobre la comprobacion
					// de estos run pendientes
					if (data.data.pendingRuns) {
						this.populatePendingRuns(data.data.pendingRuns);
					}
				},
				error: (error) => {
					console.log(error);
				}
			});
	}

	// Cargar nuevo mensaje del asistente y reproducir animacion
	async pushAssistantMessages(messages: any[]) {
		// Esta promesa se completa al finalizar la animacion de escritura
		const typedAnimation = (typedId: string, content: string, messageId: string) =>
			new Promise<void>((r) => {
				setTimeout(() => {
					// https://github.com/mattboldt/typed.js/
					const typed = new Typed(`#${typedId}`, {
						strings: [content],
						showCursor: false,
						typeSpeed: 10,
						autoInsertCss: false,
						onComplete: (self) => {
							// Remover typedId para preservar estructura original del mensaje
							// y prevenir error del maquetado al ejecutar el metodo destroy();
							if (this.thread) {
								if (this.thread.messages) {
									if (this.thread.messages[0]) {
										const message = this.thread.messages.find((message: any) => message.id == messageId);
										delete message.typedId;
									}
								}
							}

							self.destroy();
							r();
						}
					});
				}, 0);
			});

		// Iterar sobre cada mensaje
		// 1. Insertar mensaje
		// 2. Reproducir animacion de escritura
		// 3. Esperar que se complete animacion
		// 4. (si existe otro mensaje) repetir
		for (let i = 0; i < messages.length; i++) {
			const message = messages[i];
			const typedId = `typed-${uuidv4()}`;

			message.typedId = typedId;

			this.thread.messages.push(message);

			await typedAnimation(typedId, message.content[0].text.value, message.id);
		}
	}

	// Enviar mensaje a la conversacion activa - Reintentar enviar mensaje
	sendMessage(retryId: string | null = null) {
		// Si no es un reintento o si hay una conversacion activa o si el campo del mensaje no es valido
		if (!retryId && (!this.thread || !this.chatForm.valid)) {
			return;
		}

		// Mecanismo de reintento
		let retryMessage = null;

		// Si es un reintento buscar el mensaje anterior
		if (retryId) {
			retryMessage = this.thread.messages.find((message: any) => message.id == retryId);
		}

		// Asignar el mismo contenido anterior si es un reintento
		const message = retryId ? retryMessage.content[0].text.value : this.chatForm.value.message;

		// Asignar el mismo id anterior si es un reintento
		const messageId = retryId ? retryId : uuidv4();

		// Si es un reintento actualizar status
		if (retryId && retryMessage) {
			retryMessage.loading = true;
			retryMessage.error = false;
			retryMessage.created_at = DateTime.now().toSeconds();
		}

		// Añadir al array de mensajes
		if (!retryId) {
			this.thread.messages.push({
				id: messageId,
				role: 'user',
				loading: true,
				created_at: DateTime.now().toSeconds(),
				content: [
					{
						text: { value: message }
					}
				]
			});
		} else {
			// Eliminar intento anterior
			const index = this.thread.messages.findIndex((message: any) => message.id === messageId);

			if (index > -1) {
				this.thread.messages.splice(index, 1);
			}

			// Añadir nuevo intento
			this.thread.messages.push(retryMessage);
		}

		this.scrollToNew();
		this.chatForm.reset();

		this.smartChatService
			.message(this.thread.threadId, this.thread.assistantId, message, messageId)
			.pipe(takeUntil(this.unSubscribeThread), loadingState(this.loadingStates.sendingMessage))
			.subscribe({
				next: (data) => {
					// Copiar objeto mensaje del servicio
					let message = data.data.message;

					// Buscar mensaje enviado en el array de mensajes
					let sendedMessage = this.thread.messages.findIndex((message: any) => message.id == data.data.uuid);

					// Reemplazar por el nuevo objeto de mensaje con la **run data**
					this.thread.messages[sendedMessage] = message;

					// Proceso paralelo para obtener respuesta del mensaje
					this.getRunStatus(data.data.run.id, this.thread.threadId, this.thread.assistantId);
				},
				error: (error) => {
					console.log(error);

					const message = this.thread.messages.find((message: any) => message.id == messageId);
					message.loading = false;
					message.error = true;
				}
			});
	}

	// Cargar conversaciones en el navegador
	populateLocalThreads() {
		const threads = JSON.parse(localStorage.getItem('sm-threads') || '[]');

		if (threads[0]) {
			this.threads = threads;
		}
	}

	// Guardar conversaciones en el navegador
	saveLocalThreads() {
		localStorage.setItem('sm-threads', JSON.stringify(this.threads));
	}

	// Calcular si el mensaje actual esta en el día siguiente con respecto al anterior
	// PROCESO VALIDADO
	isNewDayMessage(actualDate: number, previousDate: number) {
		// Prevenir error de consultar indice inexistente anterior
		// o error de los mensajes nuevos (salientes) que no tienen "created_at"
		actualDate = actualDate ? actualDate : DateTime.now().toSeconds();
		previousDate = previousDate ? previousDate : 1000000000;

		let aDate = DateTime.fromISO(DateTime.fromSeconds(actualDate).toISODate() || '');
		let pDate = DateTime.fromISO(DateTime.fromSeconds(previousDate).toISODate() || '');
		let sDate = aDate.setLocale('es').toFormat("cccc, dd 'de' LLLL");
		let sTime = DateTime.fromSeconds(actualDate).setLocale('en').toLocaleString(DateTime.TIME_SIMPLE);

		return {
			condition: aDate > pDate,
			date: sDate,
			time: sTime
		};
	}

	// Activar conversacion
	// PROCESO VALIDADO
	setActiveThread(threadId: string) {
		const cacheThreadId = this.thread?.threadId;
		let fetch = false;

		// Si es una conversacion diferente
		if (threadId != cacheThreadId) {
			this.clearActiveThread();

			const thread = this.threads.find((thread) => thread.threadId == threadId);

			this.thread = thread;

			// Se utiliza para para añadir "loadingThread"
			fetch = true;
		}

		this.retrieveThread(fetch);
	}

	// Desactivar conversacion
	// PROCESO VALIDADO
	clearActiveThread() {
		// Cancelar llamados relacionados al thread anterior
		this.thread = null;
		this.unSubscribeThread.next();
		//this.unSubscribeThread.complete();
	}

	// Posicionar contenedor de mensajes en el mas reciente
	// PROCESO VALIDADO
	scrollToNew() {
		setTimeout(() => {
			this.messagesWrapper.nativeElement.scrollTop = 9e9;
		}, 0);
	}

	// Maximizar chat
	// PROCESO VALIDADO
	maximize() {
		this.componentStates.maximize = true;
	}

	// Minimizar chat
	// PROCESO VALIDADO
	minimize() {
		this.componentStates.maximize = false;
	}

	// Abrir chat
	// PROCESO VALIDADO
	open() {
		this.componentStates.close = false;
	}

	// Cerrar chat
	// PROCESO VALIDADO
	close() {
		this.minimize();
		this.componentStates.close = true;
	}
}
