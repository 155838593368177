import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ParallaxEffectDirective } from './directives/r3zu3/parallax-effect.directive';
import { NgxTippyModule } from 'ngx-tippy-wrapper';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SwiperModule } from 'swiper/angular';
import { SwiperComponent } from './components/swiper/swiper.component';
import { ModalOverlayDirective } from './directives/modal-overlay.directive';
import { ImageEditorOverlayComponent } from './components/image-editor-overlay/image-editor-overlay.component';
import { ImageCropperModule } from 'ngx-image-cropper';
import { NgxMaskModule, IConfig } from 'ngx-mask';
import { NgSelectModule } from '@ng-select/ng-select';
import { GoogleMapsModule } from '@angular/google-maps';
import { TabsStepperComponent } from './components/tabs-stepper/tabs-stepper.component';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { GoliiiveSeatsIoSeatingChartComponent } from './components/goliiive-seats-io-seating-chart/goliiive-seats-io-seating-chart.component';
import { LazyImageDirective } from './directives/r3zu3/lazy-image.directive';
import { FunctionZoneInventoryComponent } from './components/function-zone-inventory/function-zone-inventory.component';
import { AuthService } from '../features/auth/services/auth.service';
import { InputFieldMakerComponent } from './components/input-field-maker/input-field-maker.component';
import { IndeterminateLoaderComponent } from './components/indeterminate-loader/indeterminate-loader.component';
import { PaginationComponent } from './components/pagination/pagination.component';
import { VenuesService } from './services/venues/venues.service';
import { ToastrModule } from 'ngx-toastr';
import { ImageUploaderComponent } from './components/image-uploader/image-uploader.component';
import { ModalActionComponent } from './components/modal-action/modal-action.component';
import { ModalActionService } from './components/modal-action/modal-action.service';
import { ExperiencesService } from './services/experiences/experiences.service';
import { InputMaskModule } from '@ngneat/input-mask';
import { SmartChatComponent } from './components/smart-chat/smart-chat.component';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { DevToolService } from './directives/dev-tool.service';
import { DevToolDirective } from './directives/dev-tool.directive';
import { DevToolsComponent } from './components/dev-tools/dev-tools.component';
import { ScheduleModule } from '@syncfusion/ej2-angular-schedule';
import { ClickOutsideDirective } from './directives/click-outside.directive';
import { CdkStepperModule } from '@angular/cdk/stepper';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { CdkAccordionModule } from '@angular/cdk/accordion';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { OverlayModule } from '@angular/cdk/overlay';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { PortalModule } from '@angular/cdk/portal';
import { FirebaseMessagingService } from './services/firebase-messaging/firebase-messaging.service';
import { InputFieldComponent } from './components/input-field/input-field.component';
import { InputFieldcheckOptionTemplateDirective } from './directives/input-field-check-option-template.directive';
import { MarkdownModule } from 'ngx-markdown';
import 'src/app/shared/utils/uisr-luxon/uisr.luxon'

const MODULES = [
	CdkAccordionModule,
	CdkStepperModule,
	ClipboardModule,
	DragDropModule,
	OverlayModule,
	PortalModule,
	ScrollingModule,
	NgxTippyModule,
	FormsModule,
	SwiperModule,
	CommonModule,
	ImageCropperModule,
	NgSelectModule,
	ReactiveFormsModule,
	GoogleMapsModule,
	CKEditorModule,
	ScheduleModule,
];

const STANDALONE_MODULES = [
	NgxMaskModule.forRoot(),
	ToastrModule.forRoot(),
	InputMaskModule.forRoot({ inputSelector: 'input', isAsync: true }),
	CalendarModule.forRoot({ provide: DateAdapter, useFactory: adapterFactory }),
    MarkdownModule.forRoot()
];

const STANDALONE_MODULES_EXPORTS = [
	NgxMaskModule,
	CalendarModule
];

const COMPONENTS = [
	DevToolsComponent,
	SwiperComponent,
	ImageEditorOverlayComponent,
	TabsStepperComponent,
	GoliiiveSeatsIoSeatingChartComponent,
	FunctionZoneInventoryComponent,
	InputFieldMakerComponent,
	InputFieldComponent,
	IndeterminateLoaderComponent,
	PaginationComponent,
	ImageUploaderComponent,
	ModalActionComponent,
	SmartChatComponent
];

const DIRECTIVES = [
	ParallaxEffectDirective,
	ModalOverlayDirective,
	LazyImageDirective,
	DevToolDirective,
	ClickOutsideDirective,
    InputFieldcheckOptionTemplateDirective
];

const PIPES = [[]];

const PROVIDERS = [
	AuthService,
	VenuesService,
	ExperiencesService,
	ModalActionService,
	DevToolService,
	FirebaseMessagingService,
];

@NgModule({
	declarations: [...DIRECTIVES, ...COMPONENTS, ...PIPES],
	imports: [...MODULES, ...STANDALONE_MODULES],
	providers: [...PROVIDERS, ...PIPES],
	exports: [...MODULES, ...STANDALONE_MODULES_EXPORTS, ...DIRECTIVES, ...COMPONENTS, ...PIPES],
	schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class SharedModule {}
