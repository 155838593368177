import { Directive, TemplateRef, Self, ElementRef, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { ViewContainerRef } from '@angular/core';
import { DevToolService } from './dev-tool.service';
import { v4 as uuidv4 } from 'uuid';

@Directive({
	selector: '[appDevTool]',
	exportAs: 'appDevTool'
})
export class DevToolDirective implements OnDestroy {
	private uuid: string = uuidv4();

	constructor(
		private templateRef: TemplateRef<any>,
		private viewContainerRef: ViewContainerRef
	) {}

	@Input() set registerTool(toolName: string) {
		const hostComponent = String((this.viewContainerRef as any)['_hostLView'][0].tagName || '').toLowerCase();
		const deterministicName = `${toolName.replaceAll(' ', '-').toLowerCase()}-${hostComponent}`;
		DevToolService.registerTool(this.uuid, toolName, hostComponent, this.templateRef);
	}

	ngOnDestroy() {
		DevToolService.unregisterTool(this.uuid);
	}
}
